<template>
	<!--空间管理-->
	<mainBox mClass='spaceBox'>
		
		<div class="wapBox">
			<div class="flexs left">
				<div class="l_title">
					<div class="w50">项目</div>
					<div class="w50 evts">
						<el-dropdown trigger="click">
						  	<span class="el-dropdown-link">
						   		<span class="i_add">+</span> 添加公司/项目
						  	</span>
						  	<el-dropdown-menu slot="dropdown">
							    <el-dropdown-item>
							    	<el-button class='drBtn' type="text">添加公司</el-button>
							    </el-dropdown-item>
							    <el-dropdown-item>
							    	<el-button class='drBtn' type="text">添加项目</el-button>
							    </el-dropdown-item>
						  	</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
				<div class="treeWarp">
					<el-tree :data="treeData" :props="treeProps" ></el-tree>
				</div>
			</div>
			<div class="flexs right">
				<div class="c_name">深圳市锦绣大地投资有限公司</div>
				<div class="p_name">锦绣科学园一期</div>
				<div class="eBtns">
					<el-button class='b_it' type="primary" icon='el-icon-plus'>添加位置</el-button>
					<el-button class='b_it'>导入</el-button>
					<el-button class='b_it'>导出</el-button>
					<el-button class='b_it'>下载位置二维码</el-button>
					<el-button class='b_it' @click='initTable'>刷新</el-button>
					<el-button class='b_it' @click.stop='showRowQuery'>筛选</el-button>
				</div>
				<div class="tbBox">
					<TTable2 ref="pointTable" serialNumber checkBox
						:border='false'  :showQuery='rowQuery' 
						:moreFroms='moreFroms'
						@rowSeach='rowSeach' :tableData="tableData"  :tableTitle="tableTitle" :tbloading='tbloading' >
						<template slot='operating' slot-scope="scope">
							<el-button type="text" >禁用</el-button>
						</template>
						<template slot='phoneSlot' slot-scope="scope">
							<span>自定义查询插槽</span>
						</template>
						<template slot='sexSolt' slot-scope="scope">
							<span> 性别插槽</span>
						</template>
						<template slot="more1" slot-scope="scope">
							<el-calendar v-model="value"></el-calendar>
						</template>
					</TTable2>
					<TPagination v-model="tableParams" @initTable="initTable"></TPagination><!-- 分页 -->
				</div>
			</div>
		</div>
	</mainBox>
	
</template>

<script>
	import pagination from "@/mixins/TTable/pagination";
	import seachAndTable from "@/mixins/TTable/seachAndTable";
	import TTable2 from '@/components/YTable/TTable2.vue';
	import TPagination from '@/components/YTable/TPagination.vue';
	export default {
		mixins: [pagination,seachAndTable],
		components: {TTable2,TPagination},
		data() {
			return {
				value:'',
				treeData:[],
				treeProps:{
					label: 'projectName',
					children: 'projectChildren',
				},
				
				tableTitle:[
					{
						name:'用户名称',
						prop:"companyId",
					},
					{name:'联系电话',prop:"tell",},
					{name:'性别',prop:"sexStr",slotName:'sexSolt',queryInfo:{
							type:'select',
							value:'',
							options:[],
					},},
					
					{name:'时间',prop:"times",queryInfo:{
							type:'timePicker',
							value:'',
					},},
					{name:'开始时间',prop:"startTime",queryInfo:{
							type:'datetime',
							value:'',
					},},
					{name:'起始时间',prop:"startEndTime",queryInfo:{
							type:'datetimerange',
							value:'',
					},},
					
					
				    {name:'操作',width:"180",fixed:'right',slotName:"operating",},
				],
				moreFroms:[
					{name: '高级筛选',keys: 'otherKeys1',value: 'aaaa',type: 'input'},
					{name: '插槽11',keys: 'positionId',value: '',type:'slot',slotName:"more1",},
				],
				tableData:[
					{userName:'AAAA'},
					{userName:'BBBB'},
				],
			};
		},
		created() {},
		mounted() {
			
			setTimeout(()=>{
    			let arr=[
    				{label:'男',value:1},
    				{label:'女',value:2},
    			]
    			console.log(11111)
	    		this.common.injectionOption2('sexStr',arr,this.tableTitle)
	    		this.moreFroms[0].value='hellow';
	    	},3000)
			this.initCompanyProjects();
			this.findPositionTreeById();
		},
		computed: {},
		methods: {
			async initCompanyProjects(){
				//获取公司和项目数据
				
				let res = await this.ApiHttp('/base/tenant/project/findCompanyProjectList');
				console.log(res)
				if(res){
					res.map(it=>it.projectName=it.companyName)
					this.treeData=res;
				}
				
			},
			
			async findPositionTreeById(projectId=1){
				let res = await this.ApiHttp('/base/tenant/position/findPositionTree?projectId='+projectId);
			
				let data = this.common.listToTree(res, "positionId", "parentId");
					console.log(6666666,res,data)
//				let positionFrom= [];
//	          	let obj = this.projectList.find((item) => {
//		            return item.projectId === this.presentProjectId; //筛选出匹配数据
//	          	});
//	          	response.push({ positionId: -1, positionName: obj.projectName });
//	          	let data = this.common.listToTree(response, "positionId", "parentId");
//	          	this.recursivePosition(data);
			},
			async getPositonsInfoById(id){
				//点击树节点 获取空间信息
				let res = await this.ApiHttp('/base/tenant/position/get/'+id);
				
			},
			async initTable(){
				let params={
					...this.rowFilterData,//表格里查询的各个条件参数
					current: this.tableParams.current,
					rowCount: this.tableParams.rowCount,
				}
				this.tbloading=true;
	  			let res = await this.ApiHttp('/organization/enterprise/page',params);
	  			if(res){
	  				res.rows.map(it=>{
	  					it.creationDateStr=this.common.getTimeMmss(it.creationDate)
	  				})
	  				this.tableData = res.rows||[];
					this.tableParams.total = res.total;
	  			}
	  			this.tbloading=false;
				console.log('查询列表',params)
				
				
			},
			
		},
	};
</script>

<style scoped lang="scss">

	.wapBox{
		height: 100%;
		display: flex;
		.flexs{
			height: 100%;
		}
		.left{
			width: 320px;
			border-right: 1px solid #EEEEEE;
		}
		.l_title{
			padding: 12px 0;
			border-bottom: 1px solid #EBEEF5;
			color: #2F3941;
			display: flex;
			.w50{
				font-weight: 600;
				width: 50%;
				padding: 0 16px;
				line-height: 36px;
			}
			.evts{
				font-weight: 400;
				color: #4E5969;
			}
			
		}
		.treeWarp{
			padding: 12px 16px;
			box-sizing: border-box;
		}
		.right{
			width: calc(100% - 320px);
			padding:16px 24px;
			.c_name{
				padding: 10px 0;
				font-size: 14px;
				color: #8993A4;
			}
			.p_name{
				color: #2F3941;
				font-size: 18px;
				font-weight: bold;
				padding-bottom: 32px;
			}
			.eBtns{
				.el-button{
				    padding: 10px 18px;
				}
			}
			.tbBox{
				margin-top: 12px;
			}
		}
	}
</style>
<style type="text/css">
	.spaceBox .m-body{
		padding: 0 !important;
	}
</style>