var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mainBox", { attrs: { mClass: "spaceBox" } }, [
    _c("div", { staticClass: "wapBox" }, [
      _c("div", { staticClass: "flexs left" }, [
        _c("div", { staticClass: "l_title" }, [
          _c("div", { staticClass: "w50" }, [_vm._v("项目")]),
          _c(
            "div",
            { staticClass: "w50 evts" },
            [
              _c(
                "el-dropdown",
                { attrs: { trigger: "click" } },
                [
                  _c("span", { staticClass: "el-dropdown-link" }, [
                    _c("span", { staticClass: "i_add" }, [_vm._v("+")]),
                    _vm._v(" 添加公司/项目\n\t\t\t\t\t  \t"),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            { staticClass: "drBtn", attrs: { type: "text" } },
                            [_vm._v("添加公司")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-dropdown-item",
                        [
                          _c(
                            "el-button",
                            { staticClass: "drBtn", attrs: { type: "text" } },
                            [_vm._v("添加项目")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "treeWarp" },
          [
            _c("el-tree", {
              attrs: { data: _vm.treeData, props: _vm.treeProps },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "flexs right" }, [
        _c("div", { staticClass: "c_name" }, [
          _vm._v("深圳市锦绣大地投资有限公司"),
        ]),
        _c("div", { staticClass: "p_name" }, [_vm._v("锦绣科学园一期")]),
        _c(
          "div",
          { staticClass: "eBtns" },
          [
            _c(
              "el-button",
              {
                staticClass: "b_it",
                attrs: { type: "primary", icon: "el-icon-plus" },
              },
              [_vm._v("添加位置")]
            ),
            _c("el-button", { staticClass: "b_it" }, [_vm._v("导入")]),
            _c("el-button", { staticClass: "b_it" }, [_vm._v("导出")]),
            _c("el-button", { staticClass: "b_it" }, [
              _vm._v("下载位置二维码"),
            ]),
            _c(
              "el-button",
              { staticClass: "b_it", on: { click: _vm.initTable } },
              [_vm._v("刷新")]
            ),
            _c(
              "el-button",
              {
                staticClass: "b_it",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.showRowQuery.apply(null, arguments)
                  },
                },
              },
              [_vm._v("筛选")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tbBox" },
          [
            _c("TTable2", {
              ref: "pointTable",
              attrs: {
                serialNumber: "",
                checkBox: "",
                border: false,
                showQuery: _vm.rowQuery,
                moreFroms: _vm.moreFroms,
                tableData: _vm.tableData,
                tableTitle: _vm.tableTitle,
                tbloading: _vm.tbloading,
              },
              on: { rowSeach: _vm.rowSeach },
              scopedSlots: _vm._u([
                {
                  key: "operating",
                  fn: function (scope) {
                    return [
                      _c("el-button", { attrs: { type: "text" } }, [
                        _vm._v("禁用"),
                      ]),
                    ]
                  },
                },
                {
                  key: "phoneSlot",
                  fn: function (scope) {
                    return [_c("span", [_vm._v("自定义查询插槽")])]
                  },
                },
                {
                  key: "sexSolt",
                  fn: function (scope) {
                    return [_c("span", [_vm._v(" 性别插槽")])]
                  },
                },
                {
                  key: "more1",
                  fn: function (scope) {
                    return [
                      _c("el-calendar", {
                        model: {
                          value: _vm.value,
                          callback: function ($$v) {
                            _vm.value = $$v
                          },
                          expression: "value",
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("TPagination", {
              on: { initTable: _vm.initTable },
              model: {
                value: _vm.tableParams,
                callback: function ($$v) {
                  _vm.tableParams = $$v
                },
                expression: "tableParams",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }